<template>
    <div id="container">
        <div id="err-msg">{{errorMsg}}</div>
        <div id="btn-container">
            <button id="refresh" @click="backToHomePage">重新登陆</button>
        </div>
    </div>
</template>

<script>
import { refresh } from '../utils/urlUtils'
export default {
    name : 'Error',
    data : function() {
        return {
            errorMsg : ''
        }
    },
    
    created() {
        let reason = this.$route.query.reason
        if (reason) {
            this.errorMsg = decodeURIComponent(reason)
        } else {
            this.errorMsg = '未知错误，请联系管理员'
        }
    },

    methods : {
        backToHomePage() {
            refresh()
        }
    }
}
</script>

<style scoped>
    #container {
        display: flex;
        height: 70vh;
        flex-direction: column;
        justify-content: center;
    }

    #err-msg {
        font-weight: 500;
        font-size: larger;
        padding: 30px;
    }

    #btn-container {
        padding: 5px;
    }

    #refresh {
        color: #fff;
        background-color: #409eff;
        border-color: #409eff;
        display: inline-block;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        border: 1px solid #dcdfe6;
        text-align: center;
        box-sizing: border-box;
        outline: none;
        margin: 0;
        transition: .1s;
        font-weight: 500;
        padding: 12px 20px;
        font-size: 14px;
        border-radius: 4px;
    }
</style>